<template>
  <v-app style="background-color: black">
    <v-main>
      <router-view></router-view>

    </v-main>
    <v-footer class="footer">
      <div class="icon-array">
        <router-link to="facebook" target="_blank"><v-icon class="white--text access-icon">mdi-facebook</v-icon></router-link>
        <router-link to="youtube" target="_blank"><v-icon class="white--text access-icon">mdi-youtube</v-icon></router-link>
        <router-link to="instagram" target="_blank"><v-icon class="white--text access-icon">mdi-instagram</v-icon></router-link>

      </div>
      <div class="copycopy">
        &copy; 2021 Ever Investor, all rights reserved.
      </div>
      <v-row class="mb-10">
          <v-col align="right">
              <p class="city">São Paulo</p>
              <p class="address">Brazilian Financial Center</p>
              <p class="address">Av. Paulista 1374, 12° andar</p>
              <p class="address">São Paulo - SP</p>
          </v-col>
          <v-divider vertical inset color="gray"></v-divider>
          <v-col>
              <p class="city">Belém</p>
              <p class="address">Quadra Corporate</p>
              <p class="address">Av. Visconde de Souza Franco 05, 20° andar</p>
              <p class="address">Belém - PA</p>
          </v-col>

      </v-row>

    </v-footer>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  metaInfo: {
    htmlAttrs: {
      lang: 'en',
      amp: true
    },
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'image', content: 'https://imgur.com/lB0g82Y' },
      { property: 'og:url', content: 'https://everinvestor.com.br' },
      { property: 'og:type', content: 'website' },
      { property: 'og:image', content: 'https://imgur.com/lB0g82Y' },
      {
        name: 'description',
        content:'Evoluímos nossa empresa, nossa marca e nossa plataforma para que nossos clientes evoluam muito mais.' +
            'Após uma década compartilhando conhecimento, encarando tantos desafios, mudanças e revoluções, ' +
            'nos fortalecemos e estamos preparados para continuar em busca de conquistas maiores junto com você.'
      },
      {
        property: 'og:description',
        content:'Evoluímos nossa empresa, nossa marca e nossa plataforma para que nossos clientes evoluam muito mais.' +
            'Após uma década compartilhando conhecimento, encarando tantos desafios, mudanças e revoluções, ' +
            'nos fortalecemos e estamos preparados para continuar em busca de conquistas maiores junto com você.'
      },
      { name: 'twitter:card', content: 'summary_large_image' },
    ]
  },
  data: () => ({
    //
  }),
};
</script>
<style>

.city
{
    font-size:14pt
}

.address
{
    font-size: 10pt;
    margin-bottom: 0px !important;
    color: gray;
}

html {
  overflow-y: auto;
  background-color: black;
}


body{
  background-color: black ;
}


.footer{
  background-color: black !important;
  color: white !important;
}

.icon-array{
  display: block;
  margin-top: 60px;
  width: 100vw;
  text-align: center;
}

.icon-array a{
  text-decoration: none;
}

.copycopy{
  display: block;
  margin-top: 25px;
  margin-bottom: 50px;
  width: 100vw;
  text-align: center;
}

.access-icon{
  margin: 0 10px;
}


</style>
