<template>
  <div>
    <v-row class="main-display" align="center" justify="center">
      <v-col cols="9" sm="6" md="4">
        <v-card dark>
          <v-toolbar
            style="
              height:150px; 
              background-image: url('/icon-w.png'); 
              background-size: 90px;
              padding: 20px;
              background-position: center center"
          ></v-toolbar>
        <v-btn
          width="100%" 
          :to="'/'+$route.params.route" 
          class="py-10"
          color='green'
          style="box-shadow: 0 0 10px black"    
          >
          Continuar para {{ routes[$route.params.route] }} 
          &nbsp;&nbsp;
          <v-icon clas="ml-9">  mdi-arrow-right-drop-circle-outline </v-icon> </v-btn>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "Hub",
  data: () => ({
    routes: {
      whatsapp3: ' o WhatsApp'
    }}),
  mounted()
  {
    if(this.routes[this.$route.params.route] === undefined)
      this.$router.push('/')
  }
};
</script>

<style scoped>
.main-display {
  height: 103vh;
  background-image: url("/hub.png");
  background-position: center center;
  background-size: cover;
}

.main-statement {
  color: white;
  margin-top: 30vh;
  text-align: right;
  padding-right: 7%;
  vertical-align: middle;
}

.home-text {
  padding: 100px 7%;
  width: 40%;
  float: left;
  height: 610px;
  margin-top: -610px;
  vertical-align: middle;
  background-color: rgba(255, 255, 255, 0.9);
}

.inner-homet {
  display: block;
  vertical-align: middle;
  margin-top: 15%;
}

.left-side-picture {
  vertical-align: middle;
  background-image: url("/foto.jpg");
  background-size: cover;
  display: block;
  height: 610px;
  width: 65%;
  float: right;
  margin-top: 10px;
  background-position: center center;
}

.main-act-button {
  border: 1px solid white;
  color: white !important;
  border-radius: 10px !important;
  transition: 0.5s !important;
}

.main-act-button:hover {
  background-color: white;
  color: black !important;
}

.main-acts {
  margin-top: 25vh !important;
}
</style>
