<template>
  <div>
    <v-row justify="center" align="center">
      <v-col cols="10">
        <v-row class="py-6">
          <v-col>
            <img style="width: 200px;margin-top: 20px;vertical-align: middle" src="/logo.png" alt="">
          </v-col>
        </v-row>

        <v-card>
          <v-tabs>
            <v-tabs-slider color="black"></v-tabs-slider>
            <v-tab>Dados Pessoais</v-tab>
            <v-tab>Informações de Pagamento</v-tab>
            <v-tab>Checkout</v-tab>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Register",
  data: () => ({
    tab: 1
  }),
}
</script>

<style scoped>

</style>
