import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import Home from "./components/Home";
import Hub from "./components/Hub";
import LoadPaeg from "./LoadPaeg";
import Register from "./components/Register";

Vue.config.productionTip = false;
Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/youtube",
      component: LoadPaeg,
      beforeEnter: () =>
        (window.location.href = "https://www.youtube.com/c/everinvestor")
    },
    {
      path: "/facebook",
      component: LoadPaeg,
      beforeEnter: () =>
        (window.location.href = "https://www.facebook.com/EverInvestor")
    },
    {
      path: "/instagram",
      component: LoadPaeg,
      beforeEnter: () =>
        (window.location.href = "https://www.instagram.com/everinvestor/")
    },
    {
      path: "/whatsapp",
      component: LoadPaeg,
      beforeEnter: () =>
        (window.location.href =
          "https://api.whatsapp.com/send/?phone=5591989523906&text=Oi%2C+Quero+saber+mais+sobre+o+acompanhamento+da+Ever")
    },
    {
      path: "/whatsapp2",
      component: LoadPaeg,
      beforeEnter: () =>
        (window.location.href =
          "https://api.whatsapp.com/send?phone=5591989523906&text=Oi%2C%20Quero%20saber%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20acompanhamento%20da%20Ever!")
    },
    {
      path: "/whatsapp3",
      component: LoadPaeg,
      beforeEnter: () =>
        (window.location.href =
          "https://api.whatsapp.com/send?phone=5591989523906&text=Ol%C3%A1%2C%20Como%20funciona%20esse%20Acompanhamento%20Presencial%20que%20vi%20no%20Youtube%3F")
    },
    {
      path: "/access",
      component: LoadPaeg,
      beforeEnter: () =>
        (window.location.href = "https://aluno.everinvestor.com.br")
    },
    {
      path: "/corretora",
      component: LoadPaeg,
      beforeEnter: () =>
        (window.location.href =
          "https://www.xtb.com/int-pt?utm_source=IB&utm_campaign=IB_3046298_IBregistration")
    },
    {
      path: "/telegram",
      component: LoadPaeg,
      beforeEnter: () =>
        (window.location.href = "https://t.me/joinchat/RY4K2sOGep9jMWMx")
    },
    {
      path: "/infinox",
      component: LoadPaeg,
      beforeEnter: () =>
        (window.location.href =
          "https://myinfinox.infinox.bs/en/register?creative_id=7i2n1b8k&affid=7621ISO")
    },
    {
      path: "/trading-cup",
      component: LoadPaeg,
      beforeEnter: () =>
        (window.location.href =
          "https://br.xtb.com/trading-contest-2021-ronal?utm_source=IB&utm_campaign=IB_3046298_IBregistration&utm_content=TradingCupJunio")
    },
    {
      path: "/corretoraforex",
      component: LoadPaeg,
      beforeEnter: () =>
        (window.location.href =
          "https://br.xtb.com/trading-contest-2021-ronal-novembro?utm_source=IB&utm_campaign=IB_3046298_IBregistration&utm_content=contest_novembro")
    },
    {
      path: "*",
      component: Home
    },
    {
      path: "/hub/:route",
      component: Hub
    },
    {
      path: "/register",
      component: Register
    }
  ]
});

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount("#app");
