<template>
  <v-app style="background-color: black">


          <img src="/logo.png" alt="" style="display: block; margin: auto auto 10px auto" ><br>
        <img src="/load.gif"  width="50" style="display: block; margin: 20px auto auto auto" >

  </v-app>

</template>

<script>
export default {
  name: "LoadPaeg"
}
</script>

<style>

html {
  overflow-y: auto;
  background-color: black !important;
}

</style>
