<template>
  <div>
    <v-row class="main-display">
      <v-col>
        <div class="main-statement">
          <span
            style="padding: 10px; vertical-align: middle; margin-right: 20px"
          >
            A <i><strong>Top Trader</strong></i> mudou, agora somos
          </span>
          <img
            style="margin-top: 20px;vertical-align: middle"
            src="/logo.png"
            alt=""
          />
        </div>
        <v-row class="main-acts" justify="center">
          <v-col cols="12" md="4" lg="3" xl="2" align="center">
            <v-btn class="main-act-button" to="access" text
              >Acessar minha plataforma</v-btn
            >
          </v-col>
          <v-col cols="12" md="4" lg="3" xl="2" align="center">
            <v-btn class="main-act-button" to="whatsapp" target="_blank" text
              >Quero começar agora</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div style="background-color: black">
      <div class="left-side-picture"></div>
      <div class="home-text">
        <div class="inner-homet">
          <p>
            Evoluímos nossa empresa, nossa marca e nossa plataforma para que
            nossos clientes evoluam muito mais.
          </p>

          <p>
            Após uma década compartilhando conhecimento, encarando tantos
            desafios, mudanças e revoluções, nos fortalecemos e estamos
            preparados para continuar em busca de conquistas maiores junto com
            você.
          </p>

          <v-btn
            to="whatsapp"
            class="black white--text sac-btn pa-2"
            target="_blank"
            >Fale com a nossa equipe</v-btn
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home"
};
</script>

<style scoped>
.main-display {
  height: 103vh;
  background-image: url("/bg.jpg");
  background-position: right center;
  background-size: cover;
}

.main-statement {
  color: white;
  margin-top: 30vh;
  text-align: right;
  padding-right: 7%;
  vertical-align: middle;
}

.home-text {
  padding: 100px 7%;
  width: 40%;
  float: left;
  height: 610px;
  margin-top: -610px;
  vertical-align: middle;
  background-color: rgba(255, 255, 255, 0.9);
}

.inner-homet {
  display: block;
  vertical-align: middle;
  margin-top: 15%;
}

.left-side-picture {
  vertical-align: middle;
  background-image: url("/foto.jpg");
  background-size: cover;
  display: block;
  height: 610px;
  width: 65%;
  float: right;
  margin-top: 10px;
  background-position: center center;
}

.main-act-button {
  border: 1px solid white;
  color: white !important;
  border-radius: 10px !important;
  transition: 0.5s !important;
}

.main-act-button:hover {
  background-color: white;
  color: black !important;
}

.main-acts {
  margin-top: 25vh !important;
}

@media screen and (max-width: 850px) {
  .main-display {
    background-position: -150px center;
  }

  .main-statement {
    margin-top: 50vh;
    text-align: center;
    padding-right: 0;
    vertical-align: middle;
  }
  .main-acts {
    margin-top: 20px !important;
  }

  .left-side-picture {
    display: block;
    height: 400px;
    width: 100vw;
    float: none;
  }

  .home-text {
    padding: 10px 30px 40px 30px;
    width: 100%;
    float: none;
    height: auto;
    vertical-align: middle;
    margin-top: 0;
    background-color: rgba(255, 255, 255, 0.9);
  }

  .sac-btn {
    display: block !important;
    margin: 50px auto 20px auto !important;
  }
}
</style>
